import { FACEBOOK_PIXEL_ID, GA_TRACKING_ID, GTM_TRACKING_ID } from "~/lib/env";

interface GoogleAnalyticsProps {
  deviceId: string;
  nonce?: string;
  isEmbedded?: boolean;
}

export const GoogleAnalytics = ({
  isEmbedded,
  ...props
}: GoogleAnalyticsProps) => {
  // const { analytics, tracking } = useContext(CookieConsentContext);

  return (
    <>
      {/* gtm */}
      <script
        id={"gtm"}
        async={true}
        suppressHydrationWarning={true}
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />

      {/* fb */}
      {!isEmbedded && (
        <script
          id={"fb-pixel"}
          suppressHydrationWarning={true}
          dangerouslySetInnerHTML={{
            __html: `!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${FACEBOOK_PIXEL_ID}', {'external_id': '${props.deviceId}'});`,
          }}
        />
      )}

      {/* analytics */}
      <script
        id={"google-analytics"}
        suppressHydrationWarning={true}
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('set', 'linker', {'domains': ['app.cartamaps.com', 'cartamaps.com']});
gtag('js', new Date());
gtag('config', '${GA_TRACKING_ID}', { user_id: '${props.deviceId}' });`,
        }}
      />

      {/* tag manager */}
      <script
        id={"google-tagmanager"}
        suppressHydrationWarning={true}
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GTM_TRACKING_ID}');`,
        }}
      />
    </>
  );
};

export const GoogleNoScript = () => {
  return (
    <noscript>
      <iframe
        title="google-tagmanager-noscript"
        src={`https://www.googletagmanager.com/ns.html?id=${GTM_TRACKING_ID}`}
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      ></iframe>
      <img
        height="1"
        width="1"
        alt=""
        style={{ display: "none", visibility: "hidden" }}
        src={`https://www.facebook.com/tr?id=${FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1`}
      />
    </noscript>
  );
};
